import React, { useState } from 'react';
import "./Home.css";
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>

      {<div className='Home-Nav'>
        <div className='Home-Nav-Logo'></div>
      </div>}

      {/* ***************************************************** */}

      <div className='Home-container'>
        <div className='Home1'>
          <Link to="/Home1" className='Home-link'>
            <span className='Home-text'>Optical</span>
          </Link>
        </div>
        <div className='Home2'>
          <Link to="/Home2" className='Home-link'>
            <span className='Home-text'>Eye Hospital</span>
          </Link>
        </div>
        <div className='Home3'>
          <Link to="/Home3" className='Home-link'>
            <span className='Home-text'>ENT Clinic</span>
          </Link>
        </div>
      </div>



    </div>
  )
}

export default Home
