import React from 'react'
import { Link } from 'react-router-dom';
import "../../Optical/optical-pages/BrandOptical.css";
import OpticalNav from '../OpticalNav';


const BrandOptical = () => {

  const services = [
    {
      title: 'ZEFR',
      //   description: 'Comprehensive eye exams to assess your vision and overall eye health.',
      // link: '/ZEFR'
    },
    {
      title: 'FASTRACK',
      //   description: 'Wide selection of stylish and comfortable prescription glasses.',
      // link: '/FASTRACK'
    },
    {
      title: 'VOGUE EYEWEAR',
      //   description: 'High-quality contact lenses for all your vision needs.',
      // link: '/VOGUE EYEWEAR'
    },
    {
      title: 'RAY BAN',
      //   description: 'Comprehensive eye exams to assess your vision and overall eye health.',
      // link: '/RAY BAN'
    },
    {
      title: 'MAUI JIM',
      //   description: 'Wide selection of stylish and comfortable prescription glasses.',
      // link: '/MAUI JIM'
    },
    {
      title: 'TED-BAKER',
      //   description: 'High-quality contact lenses for all your vision needs.',
      // link: '/TED-BAKER'
    },

  ];
  return (
    <div>

      {/* ************************ Nav-Bar ****************************** */}

      <OpticalNav/>

      {/* ************************ Optical-brand-panel1 ****************************** */}

      <div className='Optical-brand-panel1' >
        <h1>Brands</h1>
      </div>



      {/* ************************ Optical-brand-pane2 ****************************** */}

      <div className='Optical-brand-pane2'>
        <div className='Optical-brand-pane2-container'>
          <div className='Optical-brand-pane2-box1'></div>
          <div className='Optical-brand-pane2-box2'></div>
          <div className='Optical-brand-pane2-box3'></div>
        </div>
      </div>

        {/* ************************ Optical-brand-pane3 ****************************** */}

        <div className='Optical-brand-pane3'>
        <div className='Optical-brand-pane3-container'>
          <div className='Optical-brand-pane3-box1'></div>
          <div className='Optical-brand-pane3-box2'></div>
          <div className='Optical-brand-pane3-box3'></div>
        </div>
      </div>

        {/* ************************ Hom-footer1 ******************************** */}

<footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h2>About Us</h2>
            <a>Our Story</a>
            <a>Terms and Conditions</a>
            <a>Privacy Policy</a>
          </div>
          <div className="footer-section">
            <h2>Products</h2>
            <a>Eyeglasses</a>
            <a>Sunglasses</a>
            <a>Contact Lens</a>
            <a>Accessories</a>
          </div>
          <div className="footer-section">
            <h2>Visit a Store</h2>
            <a>Find A Store</a>
            <a>Book an Eye Test</a>
            <a>Home Eye Test</a>
          </div>

          <div className='map-container'>
            <div style={{ marginTop: '25px' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14881.69489654796!2d79.6563695!3d21.1753201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2b3fc7f822795d%3A0xefa609f951f6f77c!2sRavi%20Opticals!5e0!3m2!1sen!2sin!4v1724395273134!5m2!1sen!2sin" width="88%" height="179px"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </footer>

      {/* ************************ Home-footer2 ******************************** */}

      <div className='footer2'>
        <p>© Ravi Eye Care 2024. All rights reserved.</p>
      </div>
      


    </div>
  )
}

export default BrandOptical