import React, { useState } from 'react';
import "../../Ent-clinic/Ent-clinic-pages/Home3.css";
import { Link } from 'react-router-dom';
import entNav from "../EntNav"
import EyeNav from '../../Eye-Hospital/EyeNav';
import EntNav from '../EntNav';

const Home3 = () => {

  const slides = [
    { id: 1, image: 'https://entdelhi.com/wp-content/uploads/2024/08/Book-Appointment1-1.jpg' },
    { id: 2, image: 'https://entdelhi.com/wp-content/uploads/2024/08/Book-Appointment1-2.jpg' },
    { id: 3, image: 'https://entdelhi.com/wp-content/uploads/2024/04/Newb3.jpeg' },
  ]

  const slides2 = [
    { id: 1, content: 'Content 1' },
    { id: 2, content: 'Content 2' },
    { id: 3, content: 'Content 3' },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideClick = () => {
    // Move to the next slide
    setCurrentSlide((prevSlide) =>
      (prevSlide + 1) % slides.length
    );
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };
  return (
    <div>
      {/* ************************ Nav-Bar ****************************** */}

      <EntNav />

      {/* ****************************** Home3 Slider1 ***************************** */}

      <div className="slider">
        <button className="prev" onClick={prevSlide}>
          &#10094;
        </button>
        <div className="slides">
          {slides.map((slide, index) => (
            <div
              key={slide.id}
              className={`slide ${index === currentSlide ? 'active' : ''}`}
              onClick={handleSlideClick}
            >
              <img src={slide.image} alt={`Slide ${slide.id}`} />
            </div>
          ))}
        </div>
        <button className="next" onClick={nextSlide}>
          &#10095;
        </button>
      </div>

      {/* ****************************** Home3-panel1 ***************************** */}

      <div className='Home3-panel1-container'>
        <h3>MedFirst ENT Centre</h3>
        <h1>ENT Clinic with Specialist Doctors</h1>

        <div className='Home3-panel1'>
          <div className='Home3-panel1-box1'>
            <img src='https://entdelhi.com/wp-content/uploads/2022/06/icon-1.png'></img>
            <p>Personal care by experienced doctor</p>
          </div>
          <div className='Home3-panel1-box2'>
            <img src='https://entdelhi.com/wp-content/uploads/2022/06/icon-2.png'></img>
            <p>On-time appointment and scheduling</p>
          </div>
          <div className='Home3-panel1-box3'>
            <img src='https://entdelhi.com/wp-content/uploads/2022/06/icon-4.png'></img>
            <p>Modern prescription handling</p>
          </div>
          <div className='Home3-panel1-box4'>
            <img src='https://entdelhi.com/wp-content/uploads/2022/06/icon-3.png'></img>
            <p>Polite and experienced dedicated staff</p>
          </div>
        </div>
      </div>

      {/* ****************************** Home3-panel2 ***************************** */}

      <div className='Home3-panel2-container'>
        <div className='Home3-panel2-left'>
          <img src='https://img.freepik.com/premium-vector/male-doctor-with-stethoscope-cartoon-round-icon-vector-illustration-graphic-design_1142-82270.jpg'></img>
        </div>
        <div className='Home3-panel2-right'>
          <h3>What we do</h3>
          <h1>ENT Consultation, Surgeries & Treatment</h1>
          <p>MedFirst ENT Centre was established in 1997. Since then it has undergone regular improvements and upgradation to become a centre of excellence for the diagnosis and management of ENT disorders in the NCR. We get  patients from all over India and overseas as well.
            We offer the latest technology and equipment and expertise in the management of ENT diseases.</p>
          <p>Dr. (Major) Rajesh Bhardwaj MBBS (AFMC), MS, DNB, DLO, DHA</p>
        </div>
      </div>

      {/* ****************************** Home3-panel3 ***************************** */}

      <div className='Home3-panel3-container'>
        <h3>Our services</h3>
        <h1>All ENT Surgeries</h1>
        <h2>The ENT Clinic Centre has the facility to perform all ENT Surgeries. Commonly performed are Mastoidectomy, Tympanoplasty, FESS(Functional Endoscopic Sinus Surgery), Septoplasty, Turbinoplasty, Tonsils and Adenoid Surgery, Surgery for Sleep Apnea, Microlaryngeal Surgery etc. Some surgeries are performed by Dr Bhardwaj and his team at the Sitaram Bhartia Hospital.</h2>

        <div className='Home3-panel3'>
          <div className='Home3-panel3-box1'>
            <img src='https://entdelhi.com/wp-content/uploads/2022/06/ENT-Surgery.png'></img>
            <p>ENT Endoscopy And Surgery From basic to advanced, all ENT Surgeries are offered at the Centre. We partner with some of the leading hospitals in South Delhi for panel patients.</p>
          </div>
          <div className='Home3-panel3-box2'>
            <img src='https://entdelhi.com/wp-content/uploads/2022/06/speech-therapy.png'></img>
            <p>Speech Therapy Speech problems can arise at any age. At our Centre we offer dedicated and qualified speech therapists.</p>
          </div>
          <div className='Home3-panel3-box3'>
            <img src='https://entdelhi.com/wp-content/uploads/2022/06/Hearing-Aids.png'></img>
            <p>Audiology And Hearing Aids Audiology aims to determine whether someone can hear within the normal range</p>
          </div>
          <div className='Home3-panel3-box4'>
            <img src='https://entdelhi.com/wp-content/uploads/2024/01/dizziness-4.png'></img>
            <p>Allergy Management and Vertigo Vertigo is a condition that causes a sensation of dizziness or spinning.</p>
          </div>
        </div>
      </div>

      {/* ****************************** Home3-panel4 ***************************** */}

      <div className='Home3-panel4-container'>
      <div className='Home3-panel4-left'>
          <h3>About the doctor</h3>
          <h1>Dr. (Major) Rajesh Bhardwaj MBBS (AFMC), MS, DNB, DLO, DHA</h1>
          <p>Dr. (Major) Bhardwaj graduated from Armed Forces Medical College (AFMC), Pune in 1982 and served in the Indian Army for 15 years, where he gained vast clinical experience as an ENT specialist,. He has served in the largest and busiest hospitals of the armed forces including Command Hospitals at Pune and Chandigarh and at the Army Hospital (R&R), Delhi Cantt.</p>
        </div>
        <div className='Home3-panel4-right'>
          <img src='https://static.vecteezy.com/system/resources/previews/041/642/091/non_2x/ai-generated-portrait-of-docto-young-woman-happy-smiling-free-png.png'></img>
        </div>
      </div>

      {/* ****************************** Home3-panel5 ***************************** */}

      <div className='Home3-panel5-container'>
        <h1>Follow us on Youtube</h1>
        <iframe src="https://www.youtube.com/embed/5wopAtCnAlo?si=GaXUqRD9C_x9S1IK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>

      {/* ************************ Hom-footer1 ******************************** */}

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h2>About Us</h2>
            <a href='/AboutOptical'>Our Story</a>
            <a href='/TermsandConditions'>Terms and Conditions</a>
            <a href='/PrivacyPolicy'>Privacy Policy</a>
          </div>
          <div className="footer-section">
            <h2>Products</h2>
            <a href='/Eyeglasses'>Eyeglasses</a>
            <a href='/Sunglasses'>Sunglasses</a>
            <a href='/Contact'>Contact Lens</a>
            <a href='/Accessories'>Accessories</a>
          </div>
          <div className="footer-section">
            <h2>Visit a Store</h2>
            <a href='/ContactOptical'>Find A Store</a>
            <a href='/BookanEyeTest'>Book an Eye Test</a>
            <a href='/HomeEyeTest'>Home Eye Test</a>
          </div>

          <div className='map-container'>
            <div style={{ marginTop: '25px' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14881.69489654796!2d79.6563695!3d21.1753201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2b3fc7f822795d%3A0xefa609f951f6f77c!2sRavi%20Opticals!5e0!3m2!1sen!2sin!4v1724395273134!5m2!1sen!2sin" width="88%" height="179px"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </footer>

      {/* ************************ Home-footer2 ******************************** */}

      <div className='footer2'>
        <p>© Ravi Eye Care 2024. All rights reserved.</p>
      </div>




    </div>
  )
}

export default Home3