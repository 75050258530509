import React from 'react';
import "../../Optical/optical-pages/AboutOptical.css";
import { Link } from 'react-router-dom';
import OpticalNav from '../OpticalNav';

const AboutOptical = () => {
  return (
    <div>


      {/* ************************ Nav-Bar ****************************** */}

      <OpticalNav/>

      {/* *********************** optical-about-panel1**************************** */}

      <div className='Optical-About-panel1'>
        <a>Home / Our Story</a> 
      </div>

      {/* *********************** optical-about-panel2**************************** */}

      <div className='Optical-About-panel2'>
        <h1>OUR STORY</h1>
        <p>Vision Express, part of the world’s leading optical retail chain, was launched in 2009. It is a Joint Venture between Reliance Retail Limited, a subsidiary of Reliance Industries, and Grand Vision, Europe, a global leader in optical retail.</p>
        <p>Benchmarked against the best in the world, Vision Express offers a wide range of high-quality eyewear in latest designs. The intent is not only to satisfy customers, but also to inspire them with professional advice. We ensure international quality standard by practices like transparent pricing, contemporary designs and free lifetime service. At Vision Express we offer not just eye wear but also eye care with our European Certified 6-step eye test conducted by certified optometrists.</p>
        <p>Vision Express has over 160 exclusive stores operating in over 30 cities.</p>
      </div>

       {/* *********************** optical-about-panel3**************************** */}

       <div className='Optical-About-panel3'>
        <h1>Reliance Retail</h1>
        <p>Reliance Retail is the retail arm of Reliance. Since its inception in 2006, Reliance Retail has grown to cater to millions of customers, and thousands of farmers and vendors. It is a multi-format retailer catering to all types of products and services across all segments for all Indian consumers. It serves over 1,00,000 customers every hour and operates over 11,300 stores pan India.</p>
      </div>

      {/* *********************** optical-about-panel4**************************** */}

      <div className='Optical-About-panel4'>
        <h1>Grand Vision</h1>
        <p>Grand Vision is a global leader in optical retail and is committed to delivering superior, high quality and affordable eye care to people around the world. With over 40 countries and 7400 stores, it has become global leaders in delivering expert optical services.</p>
      </div>

      {/* ************************ Hom-footer1 ******************************** */}

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h2>About Us</h2>
            <a>Our Story</a>
            <a>Terms and Conditions</a>
            <a>Privacy Policy</a>
          </div>
          <div className="footer-section">
            <h2>Products</h2>
            <a>Eyeglasses</a>
            <a>Sunglasses</a>
            <a>Contact Lens</a>
            <a>Accessories</a>
          </div>
          <div className="footer-section">
            <h2>Visit a Store</h2>
            <a>Find A Store</a>
            <a>Book an Eye Test</a>
            <a>Home Eye Test</a>
          </div>

          <div className='map-container'>
            <div style={{ marginTop: '25px' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14881.69489654796!2d79.6563695!3d21.1753201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2b3fc7f822795d%3A0xefa609f951f6f77c!2sRavi%20Opticals!5e0!3m2!1sen!2sin!4v1724395273134!5m2!1sen!2sin" width="88%" height="179px"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </footer>

      {/* ************************ Home-footer2 ******************************** */}

      <div className='footer2'>
        <p>© Ravi Eye Care 2024. All rights reserved.</p>
      </div>


    </div>

  )
}

export default AboutOptical