import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../Eye-Hospital/Eye.css";
import { Icon } from '@iconify/react/dist/iconify.js';

const EyeNav = () => {
  const [show, setshow] = useState(false)

  return (
    <div>

      {/* top-Bar */}

      <div className='topbar'>
        <div className='top-para'>
          <a><i className="fa-solid fa-phone"></i>    </a>
          08051069444
        </div>
        <div className='top-para'>
          <a><i className="fa-solid fa-envelope"></i>    </a>
          ravieyecare@gmail.com
        </div>


        <div class="social-media">
          <a href="https://www.facebook.com/profile.php?id=100064292403551" target="_blank" class="social-icon">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="https://www.instagram.com/ravieyecare?igsh=MWVyZ3dza3E5N2VyNQ==" target="_blank" class="social-icon">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/ravi-eye-care/" target="_blank" class="social-icon">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </div>

      </div>




      <nav className="navbar">
        <div className="logo">
          <p>RAVI EYE CARE</p>
        </div>
        <ul className="nav-links">
          <li><Link to="/Home2">Home</Link></li>
          <li><Link >About Us</Link></li>
          <li className="dropdown">
            <Link >Services</Link>
            <ul className="dropdown-menu">
              <li><Link >Contract</Link></li>
              <li><Link >Refractive Surgery</Link></li>
              <li><Link >Pediatrics Ophthalmology</Link></li>
              <li><Link >Retinal Diagnostics</Link></li>
              <li><Link >Complex Vision Syndrome</Link></li>
              <li><Link >Specialized Contact Lenses</Link></li>
              <li><Link >Glaucoma Diagnostics</Link></li>
              <li><Link >Comprehensive Diagnostics</Link></li>
              <li><Link >Well-Stocked Pharmacy</Link></li>
            </ul>
          </li>
          <li><Link >Doctor Info</Link></li>
          <li><Link>Blog</Link></li>
          <li><Link >Gallery</Link></li>
          <li><Link to={"/Home2/ContactOptical"}>Contact Us</Link></li>
        </ul>


        {
          show ? (
            <>
              <ul className="nav-links-mobile" style={{ position: 'fixed' }}>
                <button onClick={() => setshow(!show)} className={`btn-cross`}>
                  &#10005;
                </button>
                <li><Link to="/Home2">Home</Link></li>
                <li><Link >About Us</Link></li>
                <li className="dropdown">
                  <Link >Services</Link>
                  <ul className="dropdown-menu">
                    <li><Link >Contract</Link></li>
                    <li><Link >Refractive Surgery</Link></li>
                    <li><Link >Pediatrics Ophthalmology</Link></li>
                    <li><Link >Retinal Diagnostics</Link></li>
                    <li><Link >Complex Vision Syndrome</Link></li>
                    <li><Link >Specialized Contact Lenses</Link></li>
                    <li><Link >Glaucoma Diagnostics</Link></li>
                    <li><Link >Comprehensive Diagnostics</Link></li>
                    <li><Link >Well-Stocked Pharmacy</Link></li>
                  </ul>
                </li>
                <li><Link >Doctor Info</Link></li>
                <li><Link >Blog</Link></li>
                <li><Link >Gallery</Link></li>
                <li><Link to={"/Home2/ContactOptical"} >Contact Us</Link></li>
              </ul>
              <div onClick={() => setshow(false)} className='div-black'></div>
            </>
          )
            : null

        }
        <button onClick={() => setshow(true)} className={`btn-cross-open`} >
          <Icon icon="fe:bar" style={{ color: "white", fontSize: '34px' }} />
        </button>
      </nav>
    </div>
  );
}

export default EyeNav;
