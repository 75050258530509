import React, { useState } from 'react';
import "../../Eye-Hospital/Eye-Hospital-Pages/Home2.css"
import { Link } from 'react-router-dom';
import EyeNav from '../EyeNav';

const Home2 = () => {

  const boxContent = [
    { image: 'https://www.eyeqindia.com/wp-content/uploads/2022/11/Dr_Ajay_Sharma.jpg', name: 'Dr. Ajay Sharma', paragraph: 'Chief Medical Director' },
    { image: 'https://www.eyeqindia.com/wp-content/uploads/2022/11/Dr.-Deependra-Singh.jpg', name: 'Dr. Deependra Singh', paragraph: 'Medical Derector - Retina services' },
    { image: 'https://www.eyeqindia.com/wp-content/uploads/2022/11/Dr._Kruti_Shah.jpg', name: 'Dr. Kruti Shah', paragraph: 'Regional Medical Director' },
    { image: 'https://www.eyeqindia.com/wp-content/uploads/2022/11/Dr.-Ankit.jpg', name: 'Dr. Ankit Vinayak', paragraph: 'Deputy Director - Cotaract Services' },
    { image: 'https://www.eyeqindia.com/wp-content/uploads/2022/11/Dr_Anamika.jpg', name: 'Dr. Anamika Kushwaha', paragraph: 'Consultant' },
  ];

  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    appointmentDate: '',
    message: '',
    isRobot: false
  });

  const [errors, setErrors] = useState({
    name: '',
    phoneNumber: '',
    appointmentDate: '',
    message: '',
    isRobot: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = 'Name is required';
      formIsValid = false;
    }

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
      formIsValid = false;
    }

    if (!formData.appointmentDate) {
      newErrors.appointmentDate = 'Appointment date is required';
      formIsValid = false;
    }

    if (!formData.message) {
      newErrors.message = 'Message is required';
      formIsValid = false;
    }

    if (!formData.isRobot) {
      newErrors.isRobot = 'Please confirm you are not a robot';
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission logic here
      alert('Form submitted successfully!');
    }
  };



  return (
    <div>

      {/* ************************ Nav-Bar ****************************** */}

      <EyeNav />

      {/* ************************ Home2-panel1 ****************************** */}

      <div className='Home2-panel1'>
        <img src='https://www.eyeqindia.com/wp-content/uploads/2023/05/img_hp_banner-1536x608.jpg'></img>
        <div className='Home2-panel1-heading1'>
          <h1>One Stop Solution for all eye related problems</h1>
          <button>Book An Appointment</button>
        </div>
      </div>

      {/* ************************ Home2-panel2 ****************************** */}

      <div className='Home2-panel2-container'>
        <div className='Home2-panel2-first'>
          <img src='https://www.eyeqindia.com/wp-content/uploads/2024/03/google_review.png'></img>
        </div>

        <div className='Home2-panel2-second'>
          <h2>The EYE-Q Advantage</h2>
          <p>Since Inception, we’ve been offering personalized eye care to patients</p>
        </div>

        <div className='Home2-panel2-third'>
          <div className='Home2-panel2-box1'>
            <img src='https://www.eyeqindia.com/wp-content/themes/eyeqindia/assets/images/icon_expert_doctors.png'></img>
            <h2>Expert Doctors</h2>
            <p>From AIIMS & Others Owned Institutes</p>
          </div>
          <div className='Home2-panel2-box2'>
            <img src='https://www.eyeqindia.com/wp-content/themes/eyeqindia/assets/images/icon_excellence_quality.png'></img>
            <h2>Excellence in Quality</h2>
            <p>25 certified / accredited
              NABH Centers
            </p>
          </div>
          <div className='Home2-panel2-box3'>
            <img src='https://www.eyeqindia.com/wp-content/themes/eyeqindia/assets/images/icon_affordable.png'></img>
            <h2>Affordable Treatments</h2>
            <p>Tied up with insurance companies, providing you with a hassle-free experience.</p>
          </div>
        </div>
      </div>

      {/* ************************ Home2-panel3 ****************************** */}

      <div className='Home2-panel3-container'>
        <h1>Clinical Specialities at Eye-Q</h1>
        <div className='Home2-panel3-icon'>
          <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/icon_hp_tabnav9_hover.png'></img>
          <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/icon_hp_tabnav2_hover.png'></img>
          <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/icon_hp_tabnav1_hover.png'></img>
          <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/icon_hp_tabnav3_hover.png'></img>
          <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/icon_hp_tabnav4_hover.png'></img>
          <img src='https://www.eyeqindia.com/wp-content/uploads/2022/11/icon_icl_ylw.png'></img>
          <img src='https://www.eyeqindia.com/wp-content/uploads/2022/11/icon_squint_ylw.png'></img>
          <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/icon_hp_tabnav8_hover.png'></img>
        </div>

        <div className='Home2-panel3-con2'>
          <div className='Home2-panel3-con2-left'>
            <iframe width="560" height="420" src="https://www.youtube.com/embed/3NJOMIDFFIM?si=Wpx5Px_KhG-7w3Zg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>

          <div className='Home2-panel3-con2-right'>
            <h1>General Eye Care</h1>
            <p>General eye care deals with a routine check-up and common eye problems such as dry eyes, eye pain, eye infection, conjunctivitis etc. EYE-Q hospitals have a world-class infrastructure to cater to the needs of the patients. Doctors have vast experience in offering solutions to patients for eye care and treating general eye problems.</p>
            <div className='Home2-panel3-con2-right-button1'>
              <button>Book An Appointment</button>
            </div>
            <div className='Home2-panel3-con2-right-button2'>
              <button>Call For Appointment +917824001110</button>
            </div>
          </div>
        </div>
      </div>

      {/* ************************ Home2-panel4 ****************************** */}

      <div className='Home2-panel4-container'>
        <h1>Eye-Q Hospitals Locations</h1>
        <p>At Eye-Q, we have specialist doctors who have extensive experience in treating eye defects and caters to the eye problems of the people. Our topmost priority is the comfort and well-being of the patients.</p>

        <div className='Home2-panel4-img'>
          <div className='image-container'>
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/icon_gujarat.png' alt='Gujarat'></img>
            <div className='Home2-panel4-image-name'>Gujarat</div>
          </div>
          <div className='image-container'>
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/icon_haryana.png' alt='Haryana'></img>
            <div className='Home2-panel4-image-name'>Haryana</div>
          </div>
          <div className='image-container'>
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/icon_up.png' alt='UP'></img>
            <div className='Home2-panel4-image-name'>Uttar Pradesh</div>
          </div>
          <div className='image-container'>
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/icon_uk.png' alt='UK'></img>
            <div className='Home2-panel4-image-name'>Uttrakhand</div>
          </div>
        </div>
      </div>

      {/* ************************ Home2-panel5 ****************************** */}

      <div className='Home2-panel5-container'>
        <h1>Our Team of Eye Doctors</h1>
        <p>At Eye-Q, our eye specialist doctors have more than a decade of experience in treating eye patients. Our topmost priority is the comfort and well-being of the patients. EYE-Q has successfully treated more than 75 lakh patients in 17+ years.</p>

        <div className='Home2-panel5-imagebox'>
          {boxContent.map((box, index) => (
            <div key={index} className='box'>
              <img src={box.image} alt={`Image ${index + 1}`} className='box-image' />
              <h3 className='box-name'>{box.name}</h3>
              <p className='box-paragraph'>{box.paragraph}</p>
            </div>
          ))}
        </div>
      </div>

      {/* ************************ Home2-panel6 ****************************** */}

      <div className='Home2-panel6-container'>
        <h1>Mediclaim Cashless Services</h1>
        <p>Mediclaim Cashless Services can be easily availed at Eye-Q hospitals across India. Cashless mediclaim services provide flexibility of availing medical treatment by an Insured person without worrying about the Finances. Cashless mediclaim service providers offer ease to patients in case of medical emergency by settling Medical bills directly with hospitals. List of TPA (Third party administrator), Government panels and insurance companies for availing cashless Mediclaim at EYE-Q hospitals are …<a>Read more...</a></p>
        <p>Note:Prior approval of TPA is required in case of availing cashless mediclaim. Check all the conditions and procedures insurance before availing cashless mediclaim.</p>
        <button>Apply Now</button>
      </div>

      {/* ************************ Home2-panel7 ****************************** */}

      <div className='Home2-panel7-container'>
        <div className='marquee-wrapper'>
          <div className='marquee'>
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/logo_apollo.png' />
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/logo_bharti.png' />
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/logo_hdfc_ergo.png' />
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/logo_iffco.png' />
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/logo_chola.png' />
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/logo_icici.png' />
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/logo_maxbupa.png' />
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/logo_manipal.png' />
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/logo_oriental.png' />
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/logo_lt.png' />
            <img src='https://www.eyeqindia.com/wp-content/uploads/2022/10/logo_thenews.png' />
          </div>
        </div>
      </div>

      {/* ************************ Home2-panel8 ****************************** */}

      <div className='Home2-panel8-container'>
        <h1>Book An Appointment</h1>
        <p>Get the right EyeQ Care. Book your appointment with best eye specialist.</p>
      </div>

      {/* ************************ Home2-panel9 ****************************** */}

      <div className='Home2-panel9-container'>
        <div className='Home2-panel9-left'></div>
        <div className='Home2-panel9-right'>
          <h1>Please call +917824001110</h1>
          <p>Thanks for your interest in EYE-Q INDIA. Welcome to your first step towards Healthy Eyes</p>
          <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        {errors.name && <p className="error">{errors.name}</p>}
      </div>

      <div>
        <label htmlFor="phoneNumber">Phone Number:</label>
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
        {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
      </div>

      <div>
        <label htmlFor="appointmentDate">Appointment Date:</label>
        <input
          type="date"
          id="appointmentDate"
          name="appointmentDate"
          value={formData.appointmentDate}
          onChange={handleChange}
        />
        {errors.appointmentDate && <p className="error">{errors.appointmentDate}</p>}
      </div>

      <div>
        <label htmlFor="message">Your Message:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
        {errors.message && <p className="error">{errors.message}</p>}
      </div>

      <div>
        <label>
          <input
            type="checkbox"
            name="isRobot"
            checked={formData.isRobot}
            onChange={handleChange}
          />
          I'm not a robot
        </label>
        {errors.isRobot && <p className="error">{errors.isRobot}</p>}
      </div>

      <button type="submit">Submit</button>
    </form>
        </div>
      </div>

      {/* ************************ Hom-footer1 ******************************** */}

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h2>About Us</h2>
            <a href='/AboutOptical'>Our Story</a>
            <a href='/TermsandConditions'>Terms and Conditions</a>
            <a href='/PrivacyPolicy'>Privacy Policy</a>
          </div>
          <div className="footer-section">
            <h2>Products</h2>
            <a href='/Eyeglasses'>Eyeglasses</a>
            <a href='/Sunglasses'>Sunglasses</a>
            <a href='/Contact'>Contact Lens</a>
            <a href='/Accessories'>Accessories</a>
          </div>
          <div className="footer-section">
            <h2>Visit a Store</h2>
            <a href='/ContactOptical'>Find A Store</a>
            <a href='/BookanEyeTest'>Book an Eye Test</a>
            <a href='/HomeEyeTest'>Home Eye Test</a>
          </div>

          <div className='map-container'>
            <div style={{ marginTop: '25px' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14881.69489654796!2d79.6563695!3d21.1753201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2b3fc7f822795d%3A0xefa609f951f6f77c!2sRavi%20Opticals!5e0!3m2!1sen!2sin!4v1724395273134!5m2!1sen!2sin" width="88%" height="179px"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </footer>

      {/* ************************ Home-footer2 ******************************** */}

      <div className='footer2'>
        <p>© Ravi Eye Care 2024. All rights reserved.</p>
      </div>











    </div>
  )
}

export default Home2
