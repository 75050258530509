// import { Routes, Route } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import './App.css';
import Home from './Component/Homepage/Home';
import OpticalNav from './Component/Optical/OpticalNav';
import EyeNav from './Component/Eye-Hospital/EyeNav';
import EntNav from './Component/Ent-clinic/EntNav';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Home1 from './Component/Optical/optical-pages/Home1';
import AboutOptical from './Component/Optical/optical-pages/AboutOptical';
import ServicesOptical from './Component/Optical/optical-pages/ServicesOptical';
import GalleryOptical from './Component/Optical/optical-pages/GalleryOptical';
import BrandOptical from './Component/Optical/optical-pages/BrandOptical';
import ContactOptical from './Component/Optical/optical-pages/ContactOptical';
import Home2 from './Component/Eye-Hospital/Eye-Hospital-Pages/Home2';
import Home3 from './Component/Ent-clinic/Ent-clinic-pages/Home3';

function App() {
  return (
    <div>
      {/* <OpticalNav/> */}

      <Routes>

        <Route path="/" element={<Home />} />
        {/* <Route path="/OpticalNav" element={<OpticalNav />} /> */}

        {/*  Eye-Hospital-routers */}
        <Route path="/Home2" element={<Home2 />} />
        <Route path="/Home2/ContactOptical" element={<ContactOptical />} />

        {/*  Ent-clinic-routers */}
        <Route path="/Home3" element={<Home3 />} />
        <Route path="/Home3/ContactOptical" element={<ContactOptical />} />

        {/* optical-routers */}
        <Route path="/Home1" element={<Home1 />} />
        <Route path="/AboutOptical" element={<AboutOptical />} />
        <Route path="/ServicesOptical" element={<ServicesOptical />} />
        <Route path="/GalleryOptical" element={<GalleryOptical />} />
        <Route path="/BrandOptical" element={<BrandOptical />} />
        <Route path="/ContactOptical" element={<ContactOptical />} />


      </Routes>

    </div>

  );
}

export default App;
