import React, { useState } from 'react';
import "../../Optical/optical-pages/Home1.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import OpticalNav from '../OpticalNav';

const Home1 = () => {

  const slides = [
    { id: 1, image: 'https://baggaopticians.com/images/slider/banner-1.webp' },
    { id: 2, image: 'https://cdn.globalso.com/universeoptical/banner4.jpg' },
    { id: 3, image: 'https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/company/1076/applications/5ea82cc2b496555b51caafa3/theme/pictures/free/resize-w:1024/theme-image-1707220259035.jpeg?dpr=1' },
  ]

  const slides2 = [
    { id: 1, content: 'Content 1' },
    { id: 2, content: 'Content 2' },
    { id: 3, content: 'Content 3' },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideClick = () => {
    setCurrentSlide((prevSlide) =>
      (prevSlide + 1) % slides.length
    );
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  return (
    <div>

      {/* ************************ Nav-Bar ****************************** */}

      <OpticalNav />

      {/* ****************************** Home Slider1 ***************************** */}

      <div className="slider">
        <button className="prev" onClick={prevSlide}>
          &#10094;
        </button>
        <div className="slides">
          {slides.map((slide, index) => (
            <div
              key={slide.id}
              className={`slide ${index === currentSlide ? 'active' : ''}`}
              onClick={handleSlideClick}
            >
              <img src={slide.image} alt={`Slide ${slide.id}`} />
            </div>
          ))}
        </div>
        <button className="next" onClick={nextSlide}>
          &#10095;
        </button>
      </div>

      {/* *********************** Home panel******************************* */}

      <div className='Home-panel1'>
        <h1>Top International Brands</h1>
        <p>Exclusive International Brands available only at Vision Express</p>
        <div className='Home-panel1-container'>
          <div className='box1'></div>
          <div className='box2'></div>
          <div className='box3'></div>
        </div>
      </div>

      {/* ************************ Home-panel2********************************* */}

      <div className='Home-panel2'>
        <div className='Home-panel-left'></div>
        <div className='Home-panel-right'>
          <h1>VX Blue Shield Computer Glasses</h1>
          <p>Experience enhanced visual comfort and clarity with our Blue Shield computer glasses. By filtering out blue light, our glasses provide a more relaxed viewing experience, allowing you to work or play on digital devices for longer periods without experiencing eye strain or fatigue. Enjoy your screen time without any guilt!</p>
          <button>Discover Collection</button>
        </div>
      </div>

      {/* ************************ Home-panel3 ******************************** */}

      <div className='Home-panel3'>
        <h2>Discover Categories</h2>
        <div className='Home-panel3-container'>
          <div className='panel3-box1'></div>
          <div className='panel3-box2'></div>
          <div className='panel3-box3'></div>
        </div>
      </div>

      {/* ************************** Home-panel4 ********************************* */}

      <div className='Home-panel4'>
        <h1>Best Sellers</h1>
        <p>From trendy frames to premium sun protection, find the perfect pair to complement your style and enhance your vision.</p>
        <div className='Home-panel4-container'>
          <div className='panel4-box1'></div>
          <div className='panel4-box2'></div>
          <div className='panel4-box3'></div>
          <div className='panel4-box4'></div>
          <div className='panel4-box5'></div>
        </div>
      </div>

      {/* ************************** Home-panel5 ********************************* */}

      <div className='Home-panel5'>
        <div className='Home-panel5-container'>
          <div className='panel5-box1'></div>
        </div>
      </div>

      {/* ************************ Home-panel6 ******************************** */}

      <div className='Home-panel6'>
        <div className='Home-panel6-container'>
          {/* <div className='panel6-box1'></div> */}
          <div className='panel6-box2'></div>
        </div>
      </div>

      {/* ************************ footer1 ******************************** */}

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h2>About Us</h2>
            <a href='/AboutOptical'>Our Story</a>
            <a href='/Termsandconditions'>Terms and Conditions</a>
            <a href='/Privacypolicy'>Privacy Policy</a>
          </div>
          <div className="footer-section">
            <h2>Products</h2>
            <a href='/Eyeglasses'>Eyeglasses</a>
            <a href='/Sunglasses'>Sunglasses</a>
            <a href='/Contact Lens'>Contact Lens</a>
            <a href='/'>Accessories</a>
          </div>
          <div className="footer-section">
            <h2>Visit a Store</h2>
            <a href='/ContactOptical'>Find A Store</a>
            <a href='/Address 2'>Book an Eye Test</a>
            <a href='/Address 3'>Home Eye Test</a>
          </div>

          <div className='map-container'>
            <div style={{ marginTop: '25px' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14881.69489654796!2d79.6563695!3d21.1753201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2b3fc7f822795d%3A0xefa609f951f6f77c!2sRavi%20Opticals!5e0!3m2!1sen!2sin!4v1724395273134!5m2!1sen!2sin" width="88%" height="179px"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </footer>

      {/* ************************ footer2 ******************************** */}

      <div className='footer2'>
        <p>© Ravi Eye Care 2024. All rights reserved.</p>
      </div>

    </div>
  )
}

export default Home1
