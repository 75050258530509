import React, { useState } from 'react'; // Import useState
import { Link, useNavigate } from 'react-router-dom';
import "../Optical/Optical.css";
import "./optical-pages/Home1";
import "./optical-pages/ServicesOptical";
import "./optical-pages/GalleryOptical";
import "./optical-pages/BrandOptical";
import "./optical-pages/ContactOptical"
import "./OpticalNav.css"
import { Icon } from '@iconify/react/dist/iconify.js';

const OpticalNav = () => {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const navlink = [
    { name: "Home", navlink: "/Home1" },
    { name: "About Us", navlink: "/AboutOptical" },
    { name: "Brands", navlink: "/BrandOptical" },
    { name: "Services", navlink: "/ServicesOptical", hasDropdown: true },
    { name: "Gallery", navlink: "/GalleryOptical" },
    { name: "Contact Us", navlink: "/ContactOptical" }
  ];

  const servicesDropdown = [
    "Spectacles",
    "Frames",
    "Sunglasses",
    "Prescription Lenses",
    "Specialty Sunglasses",
    "Safety Sunglasses",
    "Eye Test",
    "Contact Lens",
    "Repair"
  ];

  const [show, setshow] = useState(false)

  return (
    <>

      {/* top-Bar */}

      <div className='topbar'>
        <div className='top-para'>
          <a><i className="fa-solid fa-phone"></i>    </a>
          07631134136, 08051069444
        </div>
        <div className='top-para'>
          <a><i className="fa-solid fa-envelope"></i>    </a>
          ravieyecare@gmail.com
        </div>


        <div class="social-media">
          <a href="https://www.facebook.com/profile.php?id=100064292403551" target="_blank" class="social-icon">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="https://www.instagram.com/ravieyecare?igsh=MWVyZ3dza3E5N2VyNQ==" target="_blank" class="social-icon">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/ravi-eye-care/" target="_blank" class="social-icon">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </div>


      </div>


      <div>
        <nav className="navbar">
          <div className="logo">
            <p>RAVI EYE CARE</p>
          </div>
          <ul className="nav-links">
            {navlink.map((item, index) => (
              <li
                key={index}
                onMouseEnter={() => item.hasDropdown && item.name === "Services" && setIsDropdownOpen(true)}
                onMouseLeave={() => item.hasDropdown && item.name === "Services" && setIsDropdownOpen(false)}
                onClick={() => !item.hasDropdown && navigate(item.navlink)}
                className='li-service optical-link'
                style={{ cursor: 'pointer' }}
              >
                {item.name}
                {item.hasDropdown && isDropdownOpen && item.name === "Services" && (
                  <ul className="dropdown optical_service">
                    {servicesDropdown.map((service, serviceIndex) => (
                      <li style={{ cursor: 'pointer' }} key={serviceIndex} >
                        {service}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>


          {/* -------------optical vision------------------- */}

          {
            show ?
              (
                <>
                  <ul className="nav-links-mobile" style={{ position: 'fixed' }}>
                    <button onClick={() => setshow(!show)} className={`btn-cross`}>
                      &#10005;
                    </button>
                    {navlink.map((item, index) => (
                      <li
                        key={index}
                        onMouseEnter={() => item.hasDropdown && item.name === "Services" && setIsDropdownOpen(true)}
                        onMouseLeave={() => item.hasDropdown && item.name === "Services" && setIsDropdownOpen(false)}
                        onClick={() => !item.hasDropdown && navigate(item.navlink)}
                        className='li-service optical-link'
                        style={{ cursor: 'pointer' }}
                      >
                        {item.name}
                        {item.hasDropdown && isDropdownOpen && item.name === "Services" && (
                          <ul className="dropdown optical_service">

                            {servicesDropdown.map((service, serviceIndex) => (
                              <li style={{ cursor: 'pointer' }} key={serviceIndex} >
                                {service}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                  <div onClick={() => setshow(false)} className='div-black'></div>
                </>
              )
              : null
          }


          <button onClick={() => setshow(true)} className={`btn-cross-open`} >
            <Icon icon="fe:bar" style={{ color: "white", fontSize: '34px' }} />
          </button>
        </nav>
      </div>

    </>
  );

}

export default OpticalNav;
