import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import '../Ent-clinic/Ent.css';
import { Icon } from '@iconify/react/dist/iconify.js';

const EntNav = () => {
  const [show, setshow] = useState(false)
  return (
    <div>

      {/* top-Bar */}

      <div className='topbar'>
        <div className='top-para'>
          <a><i className="fa-solid fa-phone"></i>    </a>
          7209496668
        </div>
        <div className='top-para'>
          <a><i className="fa-solid fa-envelope"></i>    </a>
          ravieyecare@gmail.com
        </div>


        <div class="social-media">
          <a href="https://www.facebook.com/profile.php?id=100064292403551" target="_blank" class="social-icon">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="https://www.instagram.com/ravieyecare?igsh=MWVyZ3dza3E5N2VyNQ==" target="_blank" class="social-icon">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/ravi-eye-care/" target="_blank" class="social-icon">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </div>


      </div>


      <nav className="navbar">
        <div className="logo">
          <p>RAVI EYE CARE</p>
        </div>
        <ul className="nav-links">
          <li><Link to="/Home3">Home</Link></li>
          <li><Link >About Us</Link></li>
          <li className="dropdown">
            <Link >Services</Link>
            <ul className="dropdown-menu">
              <li><Link >Laryngology</Link></li>
              <li><Link >Otolaryngology</Link></li>
              <li><Link >Paediatric Ophthalmology</Link></li>
              <li><Link >Vertig balance olinic</Link></li>
              <li><Link >Headache Clinic</Link></li>
              <li><Link >Audiology Hearing Aid</Link></li>
            </ul>
          </li>
          <li><Link >Doctor Info</Link></li>
          <li><Link >Hearing Aid</Link></li>
          <li><Link to={"/Home3/ContactOptical"} >Contact Us</Link></li>
        </ul>

        {
          show ? (
            <>
              <ul className="nav-links-mobile" style={{ position: 'fixed' }}>
                <button onClick={() => setshow(!show)} className={`btn-cross`}>
                  &#10005;
                </button>
                <li><Link to="/Home3">Home</Link></li>
                <li><Link >About Us</Link></li>
                <li className="dropdown">
                  <Link >Services</Link>
                  <ul className="dropdown-menu">
                    <li><Link className={`a`} >Laryngology</Link></li>
                    <li><Link className='a' >Otolaryngology</Link></li>
                    <li><Link className='a'>Paediatric Ophthalmology</Link></li>
                    <li><Link className='a'>Vertig balance olinic</Link></li>
                    <li><Link className='a'>Headache Clinic</Link></li>
                    <li><Link className='a'>Audiology Hearing Aid</Link></li>
                  </ul>
                </li>
                <li><Link >Doctor Info</Link></li>
                <li><Link >Hearing Aid</Link></li>
                <li><Link to={"/Home3/ContactOptical"} >Contact Us</Link></li>
              </ul>
              <div onClick={() => setshow(false)} className='div-black'></div>
            </>
          )
            : null


        }
        <button onClick={() => setshow(true)} className={`btn-cross-open`} >
          <Icon icon="fe:bar" style={{ color: "white", fontSize: '34px' }} />
        </button>
      </nav>

    </div>
  )
}

export default EntNav;
