import React from 'react'
import { Link } from 'react-router-dom';
import "../optical-pages/GalleryOptical.css";
import OpticalNav from '../OpticalNav';
import "../../../Assets/Images/webbanner.jpg"


const GalleryOptical = () => {

  return (
    <div>

     {/* ************************ Nav-Bar ****************************** */}

     <OpticalNav/>

      {/* ********************** GalleryOptical-panel1 **************************** */}

      <div className='GalleryOptical-panel1'>
        <img src='https://www.opticalh.com/img/cms/smart-glasses-opticalh.jpg'></img>
      </div>

      {/* ********************** GalleryOptical-panel2 **************************** */}

      <div className='GalleryOptical-panel2'>
        <h2>Smart Glasses</h2>
        <p>Uncover smart glasses at OpticalH that blend fashion and functionality. Shop smart sunglasses<br></br> and prescription glasses equipped for a connected lifestyle.</p>
      </div>

      {/* ********************** GalleryOptical-panel3 **************************** */}

      <div className='GalleryOptical-panel3'>
        <div className='GalleryOptical-panel3-container'>
          <div className='GalleryOptical-panel3-box1'></div>
          <div className='GalleryOptical-panel3-box2'></div>
          <div className='GalleryOptical-panel3-box3'></div>
          <div className='GalleryOptical-panel3-box4'></div>
          <div className='GalleryOptical-panel3-box5'></div>
        </div>
      </div>

      {/* ********************** GalleryOptical-panel4 **************************** */}

      <div className='GalleryOptical-panel4'>
        <div className='GalleryOptical-panel4-container'>
          <div className='GalleryOptical-panel4-box1'></div>
          <div className='GalleryOptical-panel4-box2'></div>
          <div className='GalleryOptical-panel4-box3'></div>
          <div className='GalleryOptical-panel4-box4'></div>
          <div className='GalleryOptical-panel4-box5'></div>
        </div>
      </div>

      {/* ********************** GalleryOptical-panel5 **************************** */}

      <div className='GalleryOptical-panel5'>
        <div className='GalleryOptical-panel4-container'>
          <div className='GalleryOptical-panel5-box1'></div>
          <div className='GalleryOptical-panel5-box2'></div>
          <div className='GalleryOptical-panel5-box3'></div>
          <div className='GalleryOptical-panel5-box4'></div>
          <div className='GalleryOptical-panel5-box5'></div>
        </div>
      </div>

      {/* ********************** GalleryOptical-panel6 **************************** */}

      <div className='GalleryOptical-panel6'>
        <h2>Smart Sunglasses</h2>
        <p>At OpticalH, we elevate the experience of wearing sunglasses to a new level with our collection of smart sunglasses. Equipped with state-of-the-art technology, these glasses transform the way you interact with the outside world. Features such as music playback through integrated speakers, hands-free calls, and voice assistants allow you to stay connected without the need to pull out your phone. Moreover, they come with the latest UV protection to ensure the safety and health of your eyes in any sunlight conditions.</p>
        <h2>Smart Prescription Glasses</h2>
        <p>Smart prescription glasses are the perfect symbiosis between advanced technological functionalities and personalized optical correction. At OpticalH, we offer smart prescription glasses that go beyond clear vision. These innovative wearables incorporate interactive features, such as displaying basic information from your smartphone and tracking your physical activity, all without compromising on optical quality and comfort for extended wear. Our smart prescription glasses are meticulously designed to meet style requirements while discreetly integrating technology into your everyday life.</p>
        <h2>The Convergence of Fashion and Technology</h2>
        <p>At OpticalH, we believe that smart glasses are testimony to how fashion and technology can converge to create accessories that are not only aesthetically appealing but also multi-functional. Whether you're looking for smart sunglasses to accompany you on your outdoor adventures or smart prescription glasses to facilitate your daily tasks, our collection is designed to fit any lifestyle. With elegant frames and seamless connectivity solutions, smart glasses are quickly becoming an indispensable accompaniment for the tech-savvy individual.</p>
        <h2>Commitment to Innovation</h2>
        <p>OpticalH is committed to being at the forefront of innovation in the field of optics. That's why we strive to provide smart glasses that align with the latest technological trends. We ensure that every pair of smart glasses we offer meets the highest standards of eye protection and digital user experience.

Explore our collection of smart glasses today and find the perfect pair that will accompany you on the road to the future of visibility and connectivity. At OpticalH, we take pride in being your gateway to the latest trends in smart glasses, both for sun protection and prescription, that will elevate your visual experience to a higher plane.</p>
      </div>

      {/* ************************ footer1 ******************************** */}

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h2>About Us</h2>
            <a>Our Story</a>
            <a>Terms and Conditions</a>
            <a>Privacy Policy</a>
          </div>
          <div className="footer-section">
            <h2>Products</h2>
            <a>Eyeglasses</a>
            <a>Sunglasses</a>
            <a>Contact Lens</a>
            <a>Accessories</a>
          </div>
          <div className="footer-section">
            <h2>Visit a Store</h2>
            <a>Find A Store</a>
            <a>Book an Eye Test</a>
            <a>Home Eye Test</a>
          </div>

          <div className='map-container'>
            <div style={{ marginTop: '25px' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14881.69489654796!2d79.6563695!3d21.1753201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2b3fc7f822795d%3A0xefa609f951f6f77c!2sRavi%20Opticals!5e0!3m2!1sen!2sin!4v1724395273134!5m2!1sen!2sin" width="88%" height="179px"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </footer>

      {/* ************************ footer2 ******************************** */}

      <div className='footer2'>
        <p>© Ravi Eye Care 2024. All rights reserved.</p>
      </div>


      

      
    </div>
  )
}

export default GalleryOptical
