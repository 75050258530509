import React from 'react'
import "../../Optical/optical-pages/ServicesOptical.css";
import { Link } from 'react-router-dom';
import OpticalNav from '../OpticalNav';

const ServicesOptical = () => {

    const services = [
        {
          title: 'Eye Exams',
          description: 'Comprehensive eye exams to assess your vision and overall eye health.',
          icon: '👁️',
          link: '/eye-exams'
        },
        {
          title: 'Prescription Glasses',
          description: 'Wide selection of stylish and comfortable prescription glasses.',
          icon: '👓',
          link: '/prescription-glasses'
        },
        {
          title: 'Contact Lenses',
          description: 'High-quality contact lenses for all your vision needs.',
          icon: '👁️‍🗨️',
          link: '/contact-lenses'
        },
        {
          title: 'Emergency Eye Care',
          description: 'Urgent care for eye injuries or sudden changes in vision.',
          icon: '🚨',
          link: '/emergency-eye-care'
        }
      ];
  return (
    <div>

        {/* ************************ Nav-Bar ****************************** */}

      <OpticalNav/>

      {/* *******************************  main-panel**************************************************** */}

      <section className="services">
  <h2>Our Services</h2>
  <div className="services-container">
    {services.map((service, index) => (
      <a key={index} href={service.link} className="service-card">
        <div className="service-icon">{service.icon}</div>
        <h3>{service.title}</h3>
        <p>{service.description}</p>
      </a>
    ))}
  </div>
</section>

{/* ************************ Hom-footer1 ******************************** */}

<footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h2>About Us</h2>
            <a>Our Story</a>
            <a>Terms and Conditions</a>
            <a>Privacy Policy</a>
          </div>
          <div className="footer-section">
            <h2>Products</h2>
            <a>Eyeglasses</a>
            <a>Sunglasses</a>
            <a>Contact Lens</a>
            <a>Accessories</a>
          </div>
          <div className="footer-section">
            <h2>Visit a Store</h2>
            <a>Find A Store</a>
            <a>Book an Eye Test</a>
            <a>Home Eye Test</a>
          </div>

          <div className='map-container'>
            <div style={{ marginTop: '25px' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14881.69489654796!2d79.6563695!3d21.1753201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2b3fc7f822795d%3A0xefa609f951f6f77c!2sRavi%20Opticals!5e0!3m2!1sen!2sin!4v1724395273134!5m2!1sen!2sin" width="88%" height="179px"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </footer>

      {/* ************************ Home-footer2 ******************************** */}

      <div className='footer2'>
        <p>© Ravi Eye Care 2024. All rights reserved.</p>
      </div>
      


    </div>
  )
}

export default ServicesOptical
