import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "../../Optical/optical-pages/ContactOptical.css";
import OpticalNav from '../OpticalNav';
import EntNav from '../../Ent-clinic/EntNav';
import EyeNav from '../../Eye-Hospital/EyeNav';


const ContactOptical = () => {
  const location = useLocation();
  return (
    <div>

      {/* ************************ Nav-Bar ****************************** */}
      {
        location.pathname == '/Home2/ContactOptical' ? <EyeNav /> : location.pathname == '/ContactOptical' ? <OpticalNav /> : <EntNav />
      }

      {/* ************************ ContactOptical-panel1 ****************************** */}

      <div className='ContactOptical-panel1'></div>

      {/* ************************ ContactOptical-panel2 ****************************** */}




      {/* ************************ ContactOptical-panel3 ****************************** */}

      <div className='ContactOptical-panel3'>
        <button className={`faq`}>FAQ</button>
        <p>We have you covered for exactly what you need. Whether its login assistance, order related queries, returns, adjustments or anything under the sun! Eye related, of course. Just email us or call us and we will try our best to help you out. Not a digital person? Just drop by any of our GKB stores near you.</p>
      </div>

      {/* ************************ ContactOptical-panel4 ****************************** */}

      <div className='ContactOptical-panel4-container'>
        <div className='ContactOptical-panel4-left'>
          <h2>For Showroom</h2>
          <img src='https://www.gkboptical.com/static/frontend/Gkb/Gkboptical/en_US/images/call-icon-cc.png'></img>
          <a>          Call us : 07631134136
            9:30am to 6:00pm (Mon-Sat)</a>
          <br></br>
          <img src='https://www.gkboptical.com/static/frontend/Gkb/Gkboptical/en_US/images/mail-icon-cc.png'></img>
          <a>          Email us : ravieyecare@gmail.com</a>
        </div>

        <div className='ContactOptical-panel4-right'>
          <h2>For Showroom</h2>
          <img src='https://www.gkboptical.com/static/frontend/Gkb/Gkboptical/en_US/images/call-icon-cc.png'></img>
          <a>          Call us : 1800 419 1990
            9:30am to 6:00pm (Mon-Sat)</a>
          <br></br>
          <img src='https://www.gkboptical.com/static/frontend/Gkb/Gkboptical/en_US/images/mail-icon-cc.png'></img>
          <a>          Email us : ravieyecare@gmail.com</a>
        </div>
      </div>

      {/* ************************ ContactOptical-panel5 ****************************** */}

      <div className='ContactOptical-panel5-cantainer'>
        <div className='ContactOptical-panel5-box1'>
          <h1>Address 1</h1>
          <p>Main - Ravi Eye Care - Aiswarya Complex, Singh More, Hatia, Ranchi - 834003</p>
          <a>No. 07631134136</a>
        </div>
        <div className='ContactOptical-panel5-box2'>
          <h1>Address 2</h1>
          <p> Branch - Ravi Opticals - Opp. Spring City Mall, Hinoo, Ranchi - 834002</p>
          <a>No. 08051069444</a>
        </div>
        <div className='ContactOptical-panel5-box3'>
          <h1>Address 3</h1>
          <p>Branch - Ravi Eye Care - Maa Bimla Plaza, Harmu Bypass Road, Argora Chowk, Ranchi - 834002</p>
          <a>No. 7209496668</a>
        </div>
      </div>


      {/* ************************ footer1 ******************************** */}

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h2>About Us</h2>
            <a>Our Story</a>
            <a>Terms and Conditions</a>
            <a>Privacy Policy</a>
          </div>
          <div className="footer-section">
            <h2>Products</h2>
            <a>Eyeglasses</a>
            <a>Sunglasses</a>
            <a>Contact Lens</a>
            <a>Accessories</a>
          </div>
          <div className="footer-section">
            <h2>Visit a Store</h2>
            <a>Find A Store</a>
            <a>Book an Eye Test</a>
            <a>Home Eye Test</a>
          </div>

          <div className='map-container'>
            <div style={{ marginTop: '25px' }}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14881.69489654796!2d79.6563695!3d21.1753201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2b3fc7f822795d%3A0xefa609f951f6f77c!2sRavi%20Opticals!5e0!3m2!1sen!2sin!4v1724395273134!5m2!1sen!2sin" width="88%" height="179px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </footer>

      {/* ************************ footer2 ******************************** */}

      <div className='footer2'>
        <p>© Ravi Eye Care 2024. All rights reserved.</p>
      </div>

    </div>
  )
}

export default ContactOptical
